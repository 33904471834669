/*
  Theme Name: Algoface - Hair Recoloring
  Support: admin@gmail.com
  Description: Algoface - Hair Recoloring Demo
  Version: 1.0
*/

/* CSS Index
-----------------------------------

1. Theme default css
2. Header
3. Search
4. Mobile-menu
5. Breadcrumb
6. Banner
7. Brand
8. Features
9. Feedback
10. Services
11. Business
12. Delivering Value
13. Team
14. Faq
15. Trial-area
16. Online-support
17. Testimonial
18. Gallery
19. Blog
20. Contact
21. Footer
22. Preloader

*/



/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600&display=swap');
body {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 16px;
	color: #5d5a77;
	font-style: normal;
}
.img {
	max-width: 100%;
	transition: all 0.3s ease-out 0s;
}
.f-left {
	float: left
}
.f-right {
	float: right
}
.fix {
	overflow: hidden
}
a,
.button {
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
a:focus,
.btn:focus,
.button:focus {
	text-decoration: none;
	outline: none;
	box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
	color: #2B96CC;
	text-decoration: none;
}
a,
button {
	color: #1696e7;
	outline: medium none;
}
button:focus,input:focus,input:focus,textarea,textarea:focus{outline: 0}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Nunito', sans-serif;
	color: #2d3748;
	margin-top: 0px;
	font-style: normal;
	font-weight: 800;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}
h1 {
	font-size: 40px;
	font-weight: 700;
}
h2 {
	font-size: 35px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
ul {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none
}
p {
	font-size: 16px;
	font-weight: 500;
	line-height: 30px;
	color: #5d5a77;
	margin-bottom: 15px;
}
hr {
	border-bottom: 1px solid #eceff8;
	border-top: 0 none;
	margin: 30px 0;
	padding: 0;
}
label {
	color: #7e7e7e;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
*::-moz-selection {
	background: #d6b161;
	color: #fff;
	text-shadow: none;
}
::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
::selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
*::-moz-placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
.theme-overlay {
	position: relative
}
.theme-overlay::before {
	background: #1696e7 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	width: 100%;
}
.separator {
	border-top: 1px solid #f2f2f2
}
.slick-slide:focus {
	outline: none;
}
/* button style */
.btn {
    background: #6153fc none repeat scroll 0 0;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 0;
    padding: 18px 31px;
    text-align: center;
    text-transform: inherit;
    touch-action: manipulation;
    transition: all ease 700ms;
    -moz-transition: all ease 700ms;
    -webkit-transition: all ease 700ms;
    -ms-transition: all ease 700ms;
    -o-transition: all ease 700ms;
    vertical-align: middle;
    white-space: nowrap;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 9px 16.74px 1.26px rgba(99, 134, 94, 0.21);
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.btn i {
	margin-right: 5px;
}
.btn:hover {
	color: #fff;
	box-shadow: none;
}
.btn span {
    background: #ff4b36 none repeat scroll 0 0;
    border-radius: 50%;
    display: block;
    height: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transition: width 0.6s ease 0s, height 0.6s ease 0s;
    -moz-transition: width 0.6s ease 0s, height 0.6s ease 0s;
    -webkit-transition: width 0.6s ease 0s, height 0.6s ease 0s;
    -ms-transition: width 0.6s ease 0s, height 0.6s ease 0s;
    -o-transition: width 0.6s ease 0s, height 0.6s ease 0s;
    width: 0;
    z-index: -1;
}
.btn:hover > span {
    height: 562.5px;
    width: 562.5px;
}
.breadcrumb > .active {
	color: #888;
}

/* scrollUp */
.scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: fixed;
    bottom: 105%;
    right: 30px;
    font-size: 16px;
    border-radius: 6px;
    z-index: 99;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    background: #6153fc;
    transition: 1s ease;
    border: none;
    opacity: 0;
}
.scroll-top.open {
	bottom: 30px;
	opacity: 1;
}
.scroll-top::after {
	position: absolute;
	z-index: -1;
	content: '';
	top: 100%;
	left: 5%;
	height: 10px;
	width: 90%;
	opacity: 1;
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
.scroll-top:hover {
	background: #ff4b36;
}


/* 2. Header */
.custom-container {
    max-width: 1510px;
}
.header-top-wrap {
    background: #6153fc;
}
.header-top-contact ul,
.header-top-right ul {
    display: flex;
    align-items: center;
	flex-wrap: wrap;
}
.header-top-contact ul li {
    margin-right: 18px;
    padding-right: 18px;
    position: relative;
}
.header-top-contact ul li::before,
.header-top-right ul li::before {
    content: "";
    position: absolute;
    right: -1px;
    top: 0;
    width: 1px;
    height: 100%;
    background: #fff;
    opacity: .12;
}
.header-top-contact ul li:last-child,
.header-top-right ul li:last-child {
	margin-right: 0;
	padding-right: 0;
}
.header-top-contact ul li:last-child:before,
.header-top-right ul li:last-child:before {
	display: none;
}
.header-top-contact ul li a {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    display: block;
    padding: 11px 0;
}
.header-top-contact ul li a i,
.header-top-right ul .header-time i {
	margin-right: 5px;
	font-size: 12px;
}
.header-top-right ul {
	justify-content: flex-end;
}
.header-top-right ul .header-time {
	color: #fff;
	font-weight: 400;
	font-size: 14px;
}
.header-top-right ul .header-social {
	color: #fff;
	font-weight: 400;
	font-size: 14px;
}
.header-top-right ul .header-social > a {
	color: #fff;
	font-size: 12px;
	margin-left: 12px;
}
.header-top-right ul li {
	padding: 11px 18px 11px 0;
	position: relative;
	margin-right: 18px;
}
.header-style-three .transparent-header {
	position: absolute;
	left: 0;
	top: 0px;
	width: 100%;
	z-index: 9;
	height: auto;
    box-shadow: none;
}
.main-header {
    padding: 14px 0;
    position: relative;
    box-shadow: 0px 9px 37.2px 2.8px rgba(128, 123, 173, 0.15);
}
.menu-nav {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.navbar-wrap {
	display: flex;
	flex-grow: 1;
}
.navbar-wrap ul {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: auto;
}
.navbar-wrap ul li {
	display: block;
	position: relative;
}
.navbar-wrap ul li a {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: #5c596f;
    padding: 35px 22px;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
}
.navbar-wrap > ul > li.menu-item-has-children > a {
    padding: 35px 35px 35px 22px;
}
.navbar-wrap > ul > li.menu-item-has-children > a::before {
    content: "\f0d7";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    color: #3b3663;
    font-size: 12px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.navbar-wrap > ul > li.active > a,
.navbar-wrap > ul > li:hover > a,
.navbar-wrap ul li .submenu li:hover >  a,
.navbar-wrap ul li .submenu li.active >  a,
.mobile-menu .navigation li.active > a {
	color: #6f54f3;
}
.navbar-wrap > ul > li.menu-item-has-children.active > a::before,
.navbar-wrap > ul > li.menu-item-has-children:hover > a::before {
	color: #6f54f3;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open {
    background: #6f54f3;
    color: #fff;
}
.main-menu .navigation li.menu-item-has-children .dropdown-btn {
    display: none;
}
.header-action > ul {
    display: flex;
    align-items: center;
    margin-left: 10px;
    position: relative;
    padding-left: 20px;
}
.header-action > ul::before {
    content: "";
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 20px;
    background: #5c596f;
    opacity: .24;
}
.header-action > ul li {
	position: relative;
	margin-left: 30px;
}
.header-action ul li:first-child {
	margin-left: 0;
}
.header-action > ul > li.header-shop-cart > a {
	padding-right: 10px;
}
.header-action > ul > li > a {
    color: #5c596f;
    font-size: 16px;
    line-height: 1;
}
.header-shop-cart a span {
	position: absolute;
	right: 0px;
	top: -2px;
	width: 16px;
	height: 16px;
	text-align: center;
	border-radius: 50%;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
	color: #222;
	box-shadow: 0px 5px 12.09px 0.91px rgba(71, 51, 127, 0.14);
}
.header-action .header-btn .btn {
    color: #fff;
    font-size: 14px;
    padding: 20px 27px;
    border-radius: 6px;
}
.navbar-wrap ul li .submenu {
	position: absolute;
	z-index: 9;
	background-color: #fff;
	border-radius: 0;
	border: none;
	-webkit-box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
	box-shadow: 0px 13px 25px -12px rgba(0,0,0,0.25);
	display: block;
	left: 0;
	opacity: 0;
	padding: 18px 0;
	right: 0;
	top: 100%;
	visibility: hidden;
	min-width: 230px;
	border: 1px solid #f5f5f5;
	background: #ffffff;
	box-shadow: 0px 30px 70px 0px rgba(137,139,142,0.15);
	margin: 0;
	transform: scale(1 , 0);
	transform-origin: 0 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.navbar-wrap ul li .submenu li {
	margin-left: 0;
	text-align: left;
	display: block;
}
.navbar-wrap ul li .submenu li a {
	padding: 0 10px 0 25px;
	line-height: 40px;
	font-weight: 500;
    font-size: 14px;
	color: #5c596f;
	text-transform: capitalize;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.navbar-wrap ul li:hover > .submenu {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
}
.sticky-menu,
.header-style-three .transparent-header.sticky-menu {
	left: 0;
	margin: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99;
	background: #fff;
	-webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
	animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
	-webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
	box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
	border-radius: 0;
}
.main-header.sticky-menu {
    padding: 2px 0;
}
.header-style-two .header-top-wrap {
    position: relative;
    background: #fff;
}
.header-style-two .header-top-wrap::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 47%;
    height: 100%;
    background: #6153fc;
    clip-path: polygon(0px 0px, 100% 0px, 97% 100%, 0% 100%);
}
.header-style-two .header-top-right ul .header-social {
    color: #3b3663;
}
.header-style-two .header-top-right ul .header-social > a {
    color: #3b3663;
}
.header-style-two .main-header {
    box-shadow: 0 20px 37.2px 0 rgb(128 123 173 / 10%);
}
.header-style-two .main-header::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 30.9%;
    height: 100%;
    background: #6153fc;
    clip-path: polygon(8.8% 0px, 100% 0px, 100% 100%, 0% 100%);
}
.header-style-two .navbar-wrap ul {
    margin-left: auto;
    margin-right: auto;
}
.header-style-two .header-action > ul::before {
    background: #fff;
}
.header-style-two .header-action > ul > li > a {
    color: #fff;
}
.header-style-two .header-action .header-btn .btn {
    color: #6153fc;
    background: #fff;
    box-shadow: none;
}
.header-style-two .header-action .header-btn .btn span {
    display: none;
}

/* 3. Search */
.search-popup-wrap {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    height: 100%;
    width: 100%;
    z-index: 99;
    padding: 100px 0;
    display: none;
}
.search-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.search-wrap .title {
    font-size: 47px;
    margin: 0 0 70px 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    letter-spacing: -1px;
	color: #3b3663;
}
.search-form {
	position: relative;
}
.search-form input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #6153fc;
    padding: 10px 50px 20px;
    text-align: center;
    font-weight: 500;
    font-size: 30px;
}
.search-form input::placeholder {
	font-size: 30px;
	opacity: .5;
}
.search-btn {
    position: absolute;
    right: 20px;
    background: transparent;
    border: 0;
    font-size: 25px;
    color: #6153fc;
    top: 50%;
    transform: translateY(-50%);
}
.search-close {
	position: absolute;
	top: 5%;
	right: 5%;
	font-size: 30px;
	color: #6153fc;
	cursor: pointer;
}

/* 4. Mobile-menu */
.nav-outer .mobile-nav-toggler {
	position: relative;
	float: right;
	font-size: 40px;
	line-height: 50px;
	cursor: pointer;
	display: none;
	color: #fff;
	margin-right: 30px;
	top: 15px;
}
.nav-logo img {
	width: 150px;
}
.mobile-menu {
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	padding-right: 30px;
	max-width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	z-index: 99;
	border-radius: 0px;
	transition: all 700ms ease;
	-moz-transition: all 700ms ease;
	-webkit-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
}
.mobile-menu .navbar-collapse {
	display: block !important;
}
.mobile-menu .nav-logo {
	position: relative;
	padding: 30px 25px;
	text-align: left;
}
.mobile-menu-visible {
	overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}
.mobile-menu .navigation li.current>a:before {
	height: 100%;
}
.menu-backdrop {
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	transition: all 700ms ease;
	-moz-transition: all 700ms ease;
	-webkit-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	opacity: 0;
	visibility: hidden;
	background: #fff;
}
.mobile-menu-visible .menu-backdrop {
	opacity: 0.80;
	visibility: visible;
}
.mobile-menu .menu-box {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	background: #fff;
	padding: 0px 0px;
	z-index: 5;
	box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
}
.mobile-menu-visible .mobile-menu .menu-box {
	opacity: 1;
	visibility: visible;
}
.mobile-menu .close-btn {
    position: absolute;
    right: 25px;
    top: 28px;
    line-height: 30px;
    width: 35px;
    text-align: center;
    font-size: 20px;
    color: #312620;
    cursor: pointer;
    z-index: 10;
    -webkit-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    transition: all 0.9s ease;
}
.mobile-menu-visible .mobile-menu .close-btn {
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
}
.mobile-menu .navigation {
	position: relative;
	display: block;
	width: 100%;
	float: none;
}
.mobile-menu .navigation li {
	position: relative;
	display: block;
	border-top: 1px solid rgb(0 0 0 / 10%);
}
.mobile-menu .navigation:last-child {
	border-bottom: 1px solid rgb(0 0 0 / 10%);
}
.mobile-menu .navigation li>ul>li:first-child {
	border-top: 1px solid rgb(0 0 0 / 10%);
}
.mobile-menu .navigation li > a {
    position: relative;
    display: block;
    line-height: 24px;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 500;
    color: #312620;
    text-transform: capitalize;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: none;
}
.mobile-menu .navigation li ul li > a {
	font-size: 15px;
	margin-left: 20px;
	text-transform: capitalize;
}
.mobile-menu .navigation li ul li ul li a {
    margin-left: 40px;
}
.mobile-menu .navigation li ul li ul li ul li a {
    margin-left: 60px;
}
.mobile-menu .navigation li > a:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 0;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
	position: absolute;
	right: 6px;
	top: 6px;
	width: 32px;
	height: 32px;
	text-align: center;
	font-size: 16px;
	line-height: 32px;
	color: #312620;
	background: #efefef;
	cursor: pointer;
	border-radius: 2px;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	z-index: 5;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
	display: none;
}
.mobile-menu .social-links {
	position: relative;
	text-align: center;
	padding: 30px 25px;
}
.mobile-menu .social-links li {
	position: relative;
	display: inline-block;
	margin: 0px 10px 10px;
}
.mobile-menu .social-links li a {
	position: relative;
	line-height: 32px;
	font-size: 16px;
	color: #312620;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.menu-area .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 30px;
    cursor: pointer;
    line-height: 1;
    color: #3b3663;
    display: none;
    margin-top: 13px;
}

/* 5. Breadcrumb */
.breadcrumb-bg {
    background-position: center;
    background-size: cover;
    padding: 148px 0;
}
.breadcrumb-content {
    position: relative;
    padding-left: 26px;
}
.breadcrumb-content::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 6px;
    border: 1px solid #ff4b36;
    border-radius: 30px;
}
.breadcrumb-content .title {
    font-size: 48px;
    color: #fff;
    font-weight: 800;
    margin-bottom: 12px;
}
.breadcrumb-content .breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
}
.breadcrumb-content .breadcrumb li {
    text-transform: uppercase;
    color: #ff4b36;
    font-weight: 500;
    font-size: 14px;
}
.breadcrumb-content .breadcrumb li a {
    color: #fff;
}
.breadcrumb-content .breadcrumb-item+.breadcrumb-item {
    padding-left: 10px;
}
.breadcrumb-content .breadcrumb-item+.breadcrumb-item::before {
    padding-right: 10px;
    color: #fff;
    content: "|";
}

/* 6. Banner */
.banner-bg {
	background-image: url(../img/slider/banner_bg.jpg);
	background-position: center;
	background-size: cover;
	padding: 80px 0 120px;
}
.banner-content .sub-title {
    display: block;
    text-transform: uppercase;
    color: #3b3663;
    font-size: 13px;
    font-weight: 900;
    letter-spacing: 1.5px;
    padding-left: 50px;
    font-family: 'Nunito', sans-serif;
    position: relative;
    margin-bottom: 14px;
}
.banner-content .sub-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 2px;
    background: #6140ff;
}
.banner-content .title {
    font-size: 60px;
    line-height: 1.1;
    color: #3b3663;
    margin-bottom: 20px;
}
.banner-content p {
    width: 80%;
	margin-bottom: 0;
}
.banner-content .banner-btn {
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.banner-content .banner-btn > a {
    margin-top: 20px;
}
.banner-content .banner-btn .free-trial {
    display: inline-flex;
    align-items: center;
    color: #84838d;
    font-size: 14px;
    font-weight: 600;
}
.banner-content .banner-btn .free-trial i {
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    background: #2c8cff;
    color: #fff;
    font-size: 12px;
    border-radius: 50%;
    flex: 0 0 26px;
    margin-left: 10px;
}
.banner-content .banner-btn .btn {
	margin-right: 30px;
}
.banner-content .banner-btn .free-trial:hover {
	color: #6153fc;
}
.banner-img {
    margin-left: -20px;
}
.banner-img img {
	max-width: inherit;
}
.slider-caption {
    line-height: 1;
}

/*===== Reveal Text =====*/
.reveal-text,
.reveal-text span {
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
    animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}
.reveal-text {
    position: relative;
    -webkit-animation-name: clip-text;
    animation-name: clip-text;
}
.reveal-text span {
    content: "";
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6153fc;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    pointer-events: none;
    -webkit-animation-name: text-revealer;
    animation-name: text-revealer;
}

@-webkit-keyframes clip-text {
	from {
		-webkit-clip-path: inset(0 100% 0 0);
		        clip-path: inset(0 100% 0 0);
	}
	to {
		-webkit-clip-path: inset(0 0 0 0);
		        clip-path: inset(0 0 0 0);
	}
}

@keyframes clip-text {
	from {
		-webkit-clip-path: inset(0 100% 0 0);
		        clip-path: inset(0 100% 0 0);
	}
	to {
		-webkit-clip-path: inset(0 0 0 0);
		        clip-path: inset(0 0 0 0);
	}
}

@-webkit-keyframes text-revealer {

	0%, 50% {
		-webkit-transform-origin: 0 50%;
		        transform-origin: 0 50%;
	}

	60%, 100% {
		-webkit-transform-origin: 100% 50%;
		        transform-origin: 100% 50%;
	}


	60% {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}

	100% {
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
	}
}

@keyframes text-revealer {

	0%, 50% {
		-webkit-transform-origin: 0 50%;
		        transform-origin: 0 50%;
	}

	60%, 100% {
		-webkit-transform-origin: 100% 50%;
		        transform-origin: 100% 50%;
	}


	60% {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}

	100% {
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
	}
}
.inner-layer {
    display: inline-block;
    overflow: hidden;
}
.slider-active .single-slider {
    margin-top: 20px;
}

/**
 * ----------------------------------------
 * animation fade-in-up
 * ----------------------------------------
 */
.fade-in-up {
    -webkit-animation-name: fade-in-up;
    animation-name: fade-in-up;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-fill-mode: both;
}
@-webkit-keyframes fade-in-up {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-up {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rubberBand
 * ----------------------------------------
 */
@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.rubber-Band {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}
.banner-img {
    position: relative;
}
.banner-img img {
    position: absolute;
}
.banner-img .main-img {
    position: inherit;
    opacity: 0;
}
.banner-img .shape-1 {
    left: 0;
    top: 15px;
}
.banner-img .shape-2 {
    left: 20%;
    top: 8%;
}
.banner-img .shape-3 {
    left: 30%;
    top: 22%;
}
.banner-img .shape-4 {
    left: 6.8%;
    top: 13.4%;
}
.banner-img .shape-5 {
    right: 9.8%;
    bottom: 0;
    animation-delay: 1s;
}
.banner-img .shape-6 {
    left: 32.3%;
    top: 0;
    animation: rubberBand 3s infinite linear;
}
.banner-img .shape-7 {
    right: 15.5%;
    top: 7%;
}
.banner-img .shape-8 {
    left: 35%;
    bottom: 24%;
}
.banner-img .shape-9 {
    bottom: 8%;
    left: 46.5%;
}
.alltuchtopdown {
	-webkit-animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
	animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
	animation-duration: 3s;
}
@-webkit-keyframes alltuchtopdown {
  0% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
  50% {
        -webkit-transform: rotateX(0deg) translateY(-10px);
        -moz-transform: rotateX(0deg) translateY(-10px);
        -ms-transform: rotateX(0deg) translateY(-10px);
        -o-transform: rotateX(0deg) translateY(-10px);
        transform: rotateX(0deg) translateY(-10px);
    }

  100% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
}
@keyframes alltuchtopdown {
  0% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
  50% {
        -webkit-transform: rotateX(0deg) translateY(-10px);
        -moz-transform: rotateX(0deg) translateY(-10px);
        -ms-transform: rotateX(0deg) translateY(-10px);
        -o-transform: rotateX(0deg) translateY(-10px);
        transform: rotateX(0deg) translateY(-10px);
    }
  100% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
}
@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.software-banner-area {
    padding: 0 0;
}
.soft-banner-wrap {
    padding: 200px 0 180px;
    position: relative;
}
.software-banner-img {
    position: absolute;
    bottom: -1px;
    left: -130px;
}
.software-banner-area .banner-content {
    padding-left: 23px;
}
.banner-content .sub-title {
    margin-bottom: 25px;
}
.software-banner-area .banner-content .title {
    font-size: 65px;
    color: #3b3663;
    letter-spacing: -1px;
}
.software-banner-area .banner-content .banner-btn {
    margin-top: 35px;
}
.software-banner-area .banner-content .banner-btn .btn {
    margin-right: 0;
    text-transform: capitalize;
    min-width: 153px;
    margin-top: 0;
}
.software-banner-shape {
    position: absolute;
    left: -13%;
    top: 12%;
}
.consulting-banner .container {
    max-width: 1420px;
}
.consulting-banner {
    padding: 190px 0 120px;
    position: relative;
}
.cons-banner-top-shape {
    position: absolute;
    left: 0;
    top: 0;
    width: 322px;
    height: 935px;
    background-repeat: no-repeat;
    z-index: -1;
}
.consulting-banner .banner-content .title {
    font-size: 65px;
}
.consulting-banner .banner-content .banner-btn {
    margin-top: 35px;
}
.consulting-banner .banner-content .banner-btn .btn {
    margin-right: 0;
    margin-top: 0;
}
.cons-banner-img {
    text-align: right;
    position: relative;
}
.cons-banner-img .main-img {
    display: inline-block;
    max-width: 100%;
}
.cons-banner-img [class*="shape-"] {
    position: absolute;
}
.cons-banner-top-shape2 {
    left: 9%;
    top: 20%;
    position: absolute;
}
.cons-banner-img .shape-2 {
    top: -20px;
    left: -65px;
    z-index: -1;
}
.cons-banner-img .shape-3 {
    right: -7%;
    top: 15px;
}
.cons-banner-img .shape-4 {
    left: 1%;
    bottom: 35%;
}
.cons-banner-img .shape-5 {
    right: -8%;
    bottom: 8%;
    z-index: -1;
}
.cons-banner-img .shape-6 {
    left: 50%;
    bottom: 1%;
}

/* 7. Brand */
.container-inner-wrap {
	padding: 0 80px;
}
.brand-wrapper {
    border-radius: 10px;
    box-shadow: 0px 5px 16.74px 1.26px rgba(60, 57, 90, 0.14);
    background: #fff;
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 60px 60px 70px;
    margin-top: -40px;
}
.brand-wrapper .title {
    font-size: 28px;
    font-weight: 600;
    color: #3b3663;
    margin-bottom: 35px;
    line-height: 1.3;
}
.brand-wrapper .title span {
	color: #ff5541;
	font-weight: 800;
}
.brand-item {
    display: flex !important;
    min-height: 43px;
    align-items: center;
    justify-content: center;
}
.brand-item > a {
    display: inline-block;
}
.cons-brand-item {
    display: flex;
    min-height: 73px;
    align-items: center;
    justify-content: center;
}
.cons-brand-item img {
    transition: .3s linear;
	cursor: pointer;
	opacity: .4;
}
.cons-brand-item img:hover {
	opacity: 1;
}

/* 8. Features */
.features-area {
	position: relative;
}
.features-item-box {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 5px 19.53px 1.47px rgba(85, 80, 98, 0.1);
    padding: 40px 35px 45px;
	overflow: hidden;
	position: relative;
	z-index: 1;
}
.features-item-icon {
    min-height: 65px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
}
.features-item-content h4 {
    font-size: 22px;
    color: #3b3663;
    margin-bottom: 15px;
	transition: all .3s ease-in-out;
}
.features-item-content p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 26px;
	transition: all .3s ease-in-out;
}
.features-item-wrap [class*="col-"]:nth-child(2) .features-item-box {
	margin-top: 65px;
}
.features-item-wrap [class*="col-"]:nth-child(3) .features-item-box {
	margin-top: -65px;
}
.features-item-wrap {
	position: relative;
}
.features-top-shape {
    position: absolute;
    left: -90px;
    top: -25px;
    z-index: -1;
}
.features-item-box::before {
    content: '';
    position: absolute;
    background: #6153fc;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition: transform .4s ease-out;
}
.features-item-box:hover::before {
    transform-origin: center top;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}
.features-item-box:hover .features-item-icon img {
    filter: brightness(100);
}
.features-item-box:hover .features-item-content h4,
.features-item-box:hover .features-item-content p {
    color: #fff;
}
.features-title .sub-title {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    background: #ff4b36;
    color: #fff;
    padding: 6px 22px;
    border-radius: 4px;
    margin-bottom: 16px;
}
.features-title .title {
    font-size: 45px;
    line-height: 1.15;
    margin-bottom: 30px;
}
.features-content-wrap {
    padding-left: 30px;
    padding-right: 20px;
}
.features-content-wrap p {
    margin-bottom: 35px;
}
.features-list ul li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}
.features-list ul li:last-child {
    margin-bottom: 0;
}
.features-list .icon {
    width: 35px;
    height: 35px;
    flex: 0 0 35px;
    margin-right: 17px;
    text-align: center;
    line-height: 35px;
    background: #ff4b36;
    border-radius: 50%;
    color: #fff;
    font-size: 13px;
}
.features-list .content {
    flex-grow: 1;
}
.features-list .content h5 {
    font-size: 20px;
    margin-bottom: 6px;
    color: #3b3663;
}
.features-list .content p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
}
.features-list {
    margin-bottom: 45px;
}
.features-right-shape {
    position: absolute;
    right: 0;
    bottom: 19%;
    z-index: -1;
}
.soft-features-area {
    background-position: center;
    background-size: cover;
}
.soft-features-item {
    padding: 70px 35px 50px 35px;
    display: flex;
    align-items: center;
    position: relative;
    transition: .3s linear;
}
.soft-features-item::before,
.soft-features-item::after {
    content: "";
    position: absolute;
    left: 35px;
    right: 45px;
    background: #fff;
    top: 20%;
    height: 4px;
    transition: .4s linear;
}
.soft-features-item::after {
    background: #ff4b36;
    left: 45%;
}
.soft-features-icon {
    width: 120px;
    flex: 0 0 120px;
    height: 120px;
    background: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 60px;
    color: #6140ff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    transition: .3s linear;
}
.soft-features-icon i {
    line-height: 0;
    display: block;
}
.soft-features-content {
    flex-grow: 1;
}
.soft-features-content > span {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 5px;
    transition: .3s linear;
}
.soft-features-content > h5 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 20px;
}
.soft-features-content > h5 a:hover {
    color: #fff;
}
.soft-features-content > .read-more {
    color: #fff;
    display: inline-flex;
    font-size: 14px;
    align-items: center;
}
.soft-features-content > .read-more i {
    transition: .3s linear;
    margin-left: 8px;
    font-size: 13px;
}
.soft-features-item:hover,
.soft-features-item.active {
    background: #3b3663;
}
.soft-features-item:hover::after,
.soft-features-item.active::after {
    left: 35px;
}
.soft-features-item:hover .soft-features-icon,
.soft-features-item:hover .soft-features-content > span,
.soft-features-item:hover .soft-features-content > .read-more i,
.soft-features-item.active .soft-features-icon,
.soft-features-item.active .soft-features-content > span,
.soft-features-item.active .soft-features-content > .read-more i {
    color: #ff4b36;
}
.cons-features-bg {
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    overflow: hidden;
}

/* 9. Feedback */
.customer-feedback-area {
    background-size: cover;
    background-position: center;
    padding: 120px 0 70px;
}
.customer-feedback-title {
    display: flex;
    align-items: center;
}
.customer-feedback-title .title {
    font-size: 42px;
    margin-bottom: 0;
    width: 75%;
    flex: 0 0 75%;
}
.customer-feedback-title .feedback-slider-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
    flex: 0 0 25%;
}
.customer-feedback-title .feedback-slider-nav button {
    width: 58px;
    height: 58px;
    padding: 0;
    line-height: 58px;
    border-radius: 50%;
    flex: 0 0 58px;
    box-shadow: none;
    background: #6153fc;
    text-align: center;
}
.customer-feedback-title .feedback-slider-nav button.slick-disabled {
    background: #a8acb8;
}
.customer-feedback-title .feedback-slider-nav button i {
    margin: 0;
}
.customer-feedback-title .feedback-slider-nav button.slick-next {
    margin-left: 20px;
}
.customer-feedback-item {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 5px 19.53px 1.47px rgba(95, 95, 100, 0.1);
    overflow: hidden;
    position: relative;
    margin: 50px 80px;
}
.feedback-item-content {
    width: 50%;
    padding: 50px 90px 55px 50px;
}
.feedback-item-content .feedback-tags {
    margin-bottom: 20px;
}
.feedback-tags ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.feedback-tags ul li {
    margin-right: 15px;
    margin-bottom: 15px;
}
.feedback-tags ul li:last-child {
    margin-right: 0;
}
.feedback-tags ul li a {
    font-size: 13px;
    color: #9795a7;
    font-weight: 400;
    display: block;
}
.feedback-tags ul li.active a {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    background: #ff4b36;
    color: #fff;
    padding: 6px 22px;
    border-radius: 4px;
}
.feedback-tags ul li.active a:hover {
    color: #fff;
}
.feedback-tags ul li a:hover {
    color: #ff4b36;
}
.feedback-item-content .title {
    font-size: 42px;
    line-height: 1.15;
    font-weight: 600;
    margin-bottom: 0;
}
.feedback-client {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 40px;
}
.feedback-client .thumb {
    width: 55px;
    flex: 0 0 55px;
    margin-right: 20px;
}
.feedback-client .thumb img {
    max-width: 100%;
    border-radius: 50%;
}
.feedback-client .info h5 {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
}
.feedback-client .info span {
    display: block;
    font-size: 13px;
}
.feedback-item-content .feedback-btn {
    border: 1px solid #6153fc;
    box-shadow: none;
    background: transparent;
    color: #6153fc;
    padding: 15px 28px;
    border-radius: 6px;
}
.feedback-item-content .feedback-btn:hover {
    background: #6153fc;
    color: #fff;
}
.customer-feedback-item .feedback-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-position: right center;
    background-size: cover;
}

/* 10. Services */
.kb-services-area {
    padding: 110px 0 90px;
}
.kb-section-title .title {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 15px;
}
.kb-section-title .title > span {
    color: #6140ff;
}
.kb-section-title p {
    width: 85%;
    margin: 0 auto;
}
.kb-services-item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 16.74px 1.26px rgba(95, 95, 100, 0.13);
    display: flex;
    align-items: flex-start;
    padding: 35px 20px;
    margin-bottom: 30px;
    position: relative;
}
.kb-services-item .kb-services-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.kb-services-item .icon {
    width: 55px;
    height: 55px;
    flex: 0 0 55px;
    text-align: center;
    background: #edecfa;
    border-radius: 50%;
    font-size: 26px;
    color: #6153fc;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.kb-services-item .icon i {
    display: block;
    line-height: 0;
}
.kb-services-item .content h5 {
    font-size: 18px;
    color: #3b3663;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
}
.kb-services-item .content h5 .new {
    font-size: 10px;
    background: #79c143;
    color: #fff;
    padding: 3px 8px;
    border-radius: 50px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-left: 13px;
    display: inline-block;
}
.kb-services-item .content p {
    font-size: 14px;
    line-height: 26px;
    color: #898799;
    margin-bottom: 0;
}
.kb-services-item:hover .icon {
    color: #fff;
    background: #6140ff;
}
.soft-services-area {
    padding: 115px 0 45px;
}
.soft-title-link {
    text-align: right;
    margin-bottom: 5px;
}
.soft-title-link a {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #5d5a77;
}
.soft-title-link a i {
    margin-left: 10px;
    color: #6140ff;
}
.soft-title-link a:hover {
    color: #6140ff;
}
.soft-services-wrap .col {
    max-width: 228px;
    flex: 0 0 228px;
}
.soft-services-item {
    /* background-image: url(../img/images/soft_services_item01.png); */
    background-size: cover;
    background-position: top center;
    min-width: 100%;
    min-height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px 24px;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
}
.soft-services-item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-image: url(../img/images/soft_services_hover01.png); */
    background-size: cover;
    background-position: top center;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    box-shadow: 0px 9px 6.51px 0.49px rgba(54, 40, 207, 0.14);
    border-radius: 30px 15px 15px 15px;
    opacity: 0;
    z-index: -1;
}
.soft-services-item:hover::before {
    opacity: 1;
}
.soft-services-icon {
    width: 100%;
    margin-bottom: 25px;
}
.soft-services-icon img {
    filter: brightness(1);
}
.soft-services-content {
    width: 100%;
}
.soft-services-content h6 {
    font-size: 16px;
    font-weight: 700;
    color: #5d5a77;
    margin-bottom: 15px;
    line-height: 1.6;
}
.soft-services-content h6 a:hover {
    color: #fff;
}
.soft-services-content .soft-services-link {
    display: inline-block;
    font-size: 18px;
    color: #6140ff;
}
.soft-services-item:hover .soft-services-icon img {
    filter: brightness(100);
}
.soft-services-item:hover .soft-services-content h6 {
    color: #fff;
}
.soft-services-item:hover .soft-services-content .soft-services-link {
    color: #fff;
}
.soft-services-wrap .col:nth-child(even) .soft-services-item {
    /* background-image: url(../img/images/soft_services_item02.png); */
}
.soft-services-wrap .col:nth-child(even) .soft-services-item::before {
    /* background-image: url(../img/images/soft_services_hover02.png); */
    border-radius: 15px 30px 15px 15px;
}
.cons-services-item {
    background: #fff;
    text-align: center;
    border-radius: 14px;
    box-shadow: 0px 9px 37.2px 2.8px rgba(128, 123, 173, 0.15);
    border: 1px solid #ebebeb;
    padding: 53px 45px 50px;
    position: relative;
    overflow: hidden;
}
.cons-services-item::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background: #ff4b36;
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
    transition: transform 0.4s cubic-bezier(.74,.72,.27,.24);
}
.cons-services-item:hover::before {
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}
.cons-services-icon {
    width: 79px;
    height: 75px;
    background: #edecfa;
    border-radius: 6px;
    margin: 0 auto 40px;
    font-size: 38px;
    color: #6140ff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cons-services-icon i {
    line-height: 0;
}
.cons-services-content .title {
    font-size: 20px;
    color: #3b3663;
    margin-bottom: 18px;
}
.cons-services-content p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
}
.cons-services-area {
    padding: 110px 0 30px;
}
.customer-services {
    padding: 110px 0 120px;
    background: #f7f6ff;
}
.customer-services-wrap {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 6px 14.88px 1.12px rgba(107, 107, 107, 0.1);
    padding: 50px 40px;
}
.customer-services-item {
    padding: 35px 38px 30px;
    position: relative;
    border-bottom: 1.5px solid #ebebeb;
    border-right: 1.5px solid #ebebeb;
}
.customer-services-top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.customer-services-top .icon {
    width: 68px;
    height: 64px;
    background: #edecfa;
    border-radius: 6px;
    flex: 0 0 68px;
    margin-right: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #6153fc;
    transition: .3s linear;
}
.customer-services-top .icon i {
    line-height: 0;
}
.customer-services-top .title {
    margin-bottom: 0;
    font-size: 22px;
    color: #3b3663;
}
.customer-services-content p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 26px;
}
.customer-services-item:hover .customer-services-top .icon {
    background: #6153fc;
    color: #fff;
}
.customer-services-wrap .row [class*="col-"]:nth-child(3) .customer-services-item,
.customer-services-wrap .row [class*="col-"]:nth-child(4) .customer-services-item {
    border-bottom: none;
}
.customer-services-wrap .row [class*="col-"]:nth-child(2) .customer-services-item,
.customer-services-wrap .row [class*="col-"]:nth-child(4) .customer-services-item {
    border-right: none;
}
.services-details-area {
    padding: 120px 0 115px;
}
.services-details-content  {
    padding: 0 30px;
}
.services-details-img {
    margin-bottom: 50px;
}
.services-details-img img {
    max-width: 100%;
    border-radius: 4px;
}
.sd-tags-wrap ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5px;
}
.sd-tags-wrap ul li {
    margin-right: 10px;
    margin-bottom: 10px;
}
.sd-tags-wrap ul li:last-child {
    margin-right: 0;
}
.sd-tags-wrap ul li a {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    background: #ff4b36;
    color: #fff;
    padding: 6px 22px;
    border-radius: 4px;
}
.services-details-content .title {
    font-size: 26px;
    margin-bottom: 18px;
    color: #3b3663;
}
.services-details-content p {
    font-size: 14px;
    line-height: 26px;
}
.services-details-content p:last-child {
    margin-bottom: 0;
}
.services-details-content .blog-details-list {
    width: 80%;
    margin: 25px 0 32px;
}
.services-details-content .sd-images-wrap {
    margin-bottom: 45px;
}
.services-details-content .sd-images-wrap img {
    max-width: 100%;
    border-radius: 4px;
}


/* 11. Business */
.business-bg {
    background-image: url(../img/bg/business_bg.png);
    padding: 260px 0 137px;
    background-position: top left;
    background-repeat: no-repeat;
    position: relative;
}
.business-img-wrap {
    position: relative;
    margin-top: 20px;
    z-index: 1;
}
.business-img-wrap::before {
    content: "";
    position: absolute;
    /* left: -20px; */
    /* top: -20px; */
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: #6153FC;
    opacity: .2;
    border-radius: 20px;
    z-index: -1;
    left: -10px;
    top: -10px;
}
.business-img-wrap .top-shape {
    position: absolute;
    left: -65px;
    top: -65px;
    z-index: -2;
}
.business-img-wrap .bottom-shape {
    position: absolute;
    right: -59px;
    bottom: 45px;
}
.business-img-wrap .main-img {
    border-radius: 20px;
    max-width: initial;
}
.business-content-wrap {
    padding-left: 45px;
}
.business-content-wrap .section-title {
    margin-bottom: 30px;
}
.business-content-wrap .section-title .sub-title {
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    color: #5d5a77;
    margin-bottom: 6px;
}
.business-content-wrap .section-title .title {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 0;
}
.business-content-wrap .section-title .title span {
    color: #6140ff;
}
.business-content-wrap > p {
    margin-bottom: -10px;
    margin-top: 15px;
    font-size: large;
}siness-content-list li {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 75%;
}
.business-content-list .icon {
    width: 40px;
    flex: 0 0 40px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    background: #6153fc;
    color: #fff;
    font-size: 14px;
    border-radius: 50%;
    margin-right: 20px;
    transition: .2s linear;
}
.business-content-list .content {
    flex-grow: 1;
}
.business-content-list .content h5 {
    font-size: 20px;
    color: #3b3663;
    margin-bottom: 7px;
}
.business-content-list .content p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0;
}
.business-content-list li:hover .icon {
    background: #ff4b36;
}
.business-content-list li + li {
    margin-top: 40px;
}
.business-content-list li + li::before {
    content: "";
    position: absolute;
    left: 20px;
    top: -70px;
    width: 1px;
    height: 55px;
    background: #b7b6b6;
}
.business-content-list {
    margin-top: 34px;
}

/* 12. Delivering Value */
.delivering-value-item {
    margin-bottom: 45px;
}
.dv-thumb {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
}
.dv-thumb img {
    max-width: 100%;
    transition: .3s linear;
}
.dv-content-wrap {
    display: flex;
    align-items: flex-start;
}
.dv-content-wrap .icon {
    width: 65px;
    height: 65px;
    flex: 0 0 65px;
    background: #6153fc;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 4px;
    margin-right: 20px;
    font-size: 30px;
    transition: .3s linear;
}
.dv-content-wrap .icon i {
    line-height: 0;
    display: block;
}
.dv-content-wrap .title {
    font-size: 22px;
    margin-bottom: 11px;
    color: #3b3663;
}
.dv-content-wrap .title a:hover,
.dv-content-wrap .content > a:hover {
    color: #ff4b36;
}
.dv-content-wrap p {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 18px;
}
.dv-content-wrap .content > a {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #6153fc;
}
.dv-content-wrap .content > a i {
    margin-left: 7px;
}
.delivering-value-item:hover .dv-content-wrap .icon {
    background: #ff4b36;
}
.delivering-value-item:hover .dv-thumb img {
    transform: scale(1.1);
}

/* 13. Team */
.team-bg {
    background-position: center;
    background-size: cover;
    padding: 110px 0 66px;
    position: relative;
    z-index: 1;
}
.team-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-image: url(../img/bg/team_bg_shape.png); */
    background-position: center;
    background-size: cover;
    opacity: .62;
}
.team-bg::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #4d3ef0;
    opacity: .93;
    z-index: -1;
}
.white-title .title {
    color: #fff;
}
.white-title .title > span {
    color: #ff4b36;
}
.white-title p {
    color: #f0f0f0;
}
.team-item {
    position: relative;
    text-align: center;
    margin-bottom: 50px;
    z-index: 1;
}
.team-thumb img {
    border-radius: 50%;
    border: 8px solid #6153fc;
    margin-top: 2px;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}
.team-thumb > .thumb-bg {
    border-radius: 50%;
    width: 194px;
    height: 194px;
    box-shadow: 0px 9px 6.51px 0.49px rgba(17, 7, 128, 0.14);
    background: #fff;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%) rotate(0deg);
    -webkit-transition: all 0.6s ease-out 0s;
    -moz-transition: all 0.6s ease-out 0s;
    -ms-transition: all 0.6s ease-out 0s;
    -o-transition: all 0.6s ease-out 0s;
    transition: all 0.6s ease-out 0s;
    z-index: -1;
}
.team-thumb > .thumb-bg::before {
    content: "";
    position: absolute;
    right: 0%;
    top: 16%;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
}
.team-content > span {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    margin-bottom: 5px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.team-content h5 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 14px;
}
.team-content h5 a:hover {
    color: #fff;
}
.team-content p {
    margin-bottom: 0;
    font-size: 14px;
    color: #e7e7e7;
    line-height: 26px;
}
.team-item .team-thumb {
    margin-bottom: 55px;
}
.team-item:hover .team-content > span {
    color: #ff4b36;
}
.team-item:hover .team-thumb > .thumb-bg {
    transform: translateX(-50%) rotate(65deg);
}
.team-item:hover .team-thumb img {
    border-color: #ff4b36;
}

/* 14. Faq */
.faq-img {
    padding-top: 20px;
    position: relative;
}
.faq-img::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 0;
    width: 516px;
    height: 534px;
    /* background-image: url(../img/images/faq_img_shape01.png); */
    z-index: -1;
    border-radius: 15px;
}
.faq-img .faq-bottom-shape {
    border-radius: 0;
    position: absolute;
    right: 12%;
    bottom: -36px;
}
.faq-img img {
    max-width: 100%;
    border-radius: 15px;
}
.section-title-two .sub-title {
    font-size: 34px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 5px;
}
.section-title-two .title {
    font-size: 40px;
    letter-spacing: -.5px;
    margin-bottom: 0;
}
.section-title-two .title > span {
    color: #6140ff;
}
.faq-area .section-title-two {
    margin-bottom: 28px;
}
.faq-search-form form {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}
.faq-search-form form input {
    flex-grow: 1;
    border: none;
    background: #f4f3ff;
    padding: 19px 25px;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    font-weight: 500;
}
.faq-search-form form input::placeholder {
    font-size: 13px;
    color: #aeadb7;
    font-weight: 500;
}
.faq-search-form form button {
    box-shadow: none;
    border-radius: 0 4px 4px 0;
    min-height: 59px;
}
.faq-search-form form button:hover {
    background: #ff4b36;
}
.faq-nav-tabs.nav-tabs {
    border: none;
    margin-left: -8px;
    margin-right: -8px;
}
.faq-nav-tabs .nav-item {
    width: 33.3333%;
    flex: 0 0 33.3333%;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
}
.faq-nav-tabs .nav-item .nav-link {
    display: flex;
    flex-direction: column;
    border: 1px solid #e1e1e1;
    background: #f4f3ff;
    border-radius: 4px;
    padding: 20px 20px;
    margin-bottom: 0;
}
.faq-nav-tabs .nav-item .nav-link i {
    font-size: 19px;
    line-height: 1;
    color: #6153fc;
    margin-bottom: 6px;
}
.faq-nav-tabs .nav-item .nav-link .heading {
    display: block;
    color: #3b3663;
    text-transform: capitalize;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    margin-bottom: 1px;
}
.faq-nav-tabs .nav-item .nav-link .desc {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #5d5a77;
}
.faq-nav-tabs .nav-item .nav-link.active {
    background: #fff;
    box-shadow: 0px 7px 14.88px 1.12px rgba(5, 51, 102, 0.15);
    border-color: #6153fc;
}
.faq-wrapper .card {
    border: none;
    border-radius: 0;
    margin-bottom: 21px;
}
.faq-wrapper .card:last-child {
    margin-bottom: 0;
}
.faq-wrapper .card-header {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: none;
}
.faq-wrapper .card-header .btn-link {
    text-align: left;
    font-size: 16px;
    font-weight: 800;
    color: #3b3663;
    border: none;
    background: transparent;
    border-bottom: 1px solid #bbb5fe;
    width: 100%;
    padding: 0 20px 13px 0;
    line-height: 1.7;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    position: relative;
}
.faq-wrapper .card-header .btn-link.collapsed {
    border-color: #e7e7ec;
}
.faq-wrapper .card-header .btn-link:hover {
    text-decoration: none;
}
.faq-wrapper .card-header .btn-link .count {
    margin-right: 14px;
    color: #6153fc;
}
.faq-wrapper > .card > .card-header {
    border-radius: 0;
    margin-bottom: 0;
}
.faq-wrapper .card-body {
    padding: 12px 25px 0;
    font-size: 13px;
    color: #827f93;
    line-height: 24px;
}
.faq-wrapper .card .card-header button::after {
    position: absolute;
    content: "\f106";
    top: 0;
    right: 0;
    font-size: 14px;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    color: #6153fc;
}
.faq-wrapper .card .card-header button.collapsed:after {
	content: "\f107";
}
.faq-tab-content {
    margin-top: 20px;
}
.faq-style-two .faq-img img {
    box-shadow: 0px 7px 14.88px 1.12px rgba(5, 51, 102, 0.15);
}
.faq-style-two .faq-img .faq-bottom-shape {
    box-shadow: none;
}

/* 15. Trial-area */
.kb-trial-wrap {
    background: #6153fc;
    border-radius: 10px;
    padding: 90px 50px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.kb-trial-wrap .title {
    font-size: 28px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 1.45;
    margin-bottom: 0;
    width: 43%;
    padding-right: 40px;
}
.kb-trial-form {
    flex-grow: 1;
}
.kb-trial-form form {
    display: flex;
    align-items: center;
}
.kb-trial-form input {
    background: #fff;
    border: none;
    padding: 18px 25px;
    font-size: 14px;
    border-radius: 4px;
    width: 61%;
    flex: 0 0 61%;
    font-weight: 500;
}
.kb-trial-form input::placeholder {
    font-size: 14px;
    color: #918fa1;
    font-weight: 500;
}
.kb-trial-form button {
    background: #ffea01;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: #3b3663;
    font-family: 'Poppins', sans-serif;
    box-shadow: none;
    padding: 18px 29px;
    margin-left: 18px;
    min-width: 206px;
}
.kb-trial-form button span {
    background: #222;
}
.kb-trial-wrap .trial-shape {
    position: absolute;
    z-index: -1;
}
.kb-trial-wrap .top-shape {
    left: 0;
    top: 0;
}
.kb-trial-wrap .bottom-shape {
    bottom: 0;
    right: 0;
}

/* 16. Online-support */
.online-support-area {
    padding: 120px 0;
}
.online-support-img {
    text-align: center;
}
.online-support-img img {
    max-width: 100%;
}
.online-support-content {
    padding-right: 70px;
}
.online-support-content .title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 15px;
}
.online-support-content > p {
    color: #898799;
    font-weight: 400;
    margin-bottom: 0;
}
.support-info-item {
    text-align: center;
    position: relative;
    margin-top: 35px;
}
.support-info-item p {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 26px;
    margin-bottom: 6px;
}
.support-info-item h2 {
    font-size: 45px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #898799;
    font-weight: 600;
}
.support-info-item h2 i {
    font-size: 40px;
    color: #6153fc;
    display: block;
    line-height: 0;
    margin-right: 15px;
}
.support-info-item::after {
    content: "";
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 100%;
    background: #ebebeb;
}
.support-info-wrap .row [class*="col-"]:last-child .support-info-item::after {
    display: none;
}

/* 17. Testimonial */
.testimonial-area {
    padding: 110px 0 40px;
    position: relative;
}
.testimonial-area .container-inner-wrap {
    padding: 0 205px;
    position: relative;
}
.cons-testi-img {
    text-align: right;
}
.cons-testi-img img {
    max-width: initial;
    display: inline-block;
}
.cons-testi-content-wrap {
    padding-left: 15px;
}
.cons-testi-quote {
    font-size: 28px;
    color: #fabc32;
    line-height: 1;
    margin-bottom: 32px;
}
.cons-testi-content .title {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 900;
    color: #3b3663;
    margin-bottom: 18px;
}
.cons-testi-content p {
    margin-bottom: 0;
}
.cons-testi-avatar {
    margin-top: 27px;
}
.cons-testi-avatar h5 {
    font-size: 18px;
    color: #3b3663;
    margin-bottom: 6px;
}
.cons-testi-avatar span {
    display: block;
    font-size: 14px;
}
.testimonial-slider-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20%;
    margin-top: 45px;
}
.testimonial-slider-nav button {
    border: none;
    padding: 0;
    background: transparent;
    font-size: 33px;
    line-height: 0;
    color: #878787;
    transition: .3s linear;
    margin-left: 8px;
}
.testimonial-slider-nav button.slick-prev {
    transform: rotate(180deg);
}
.testimonial-slider-nav button:hover {
    color: #ff4b36;
}
.testi-top-shape {
    position: absolute;
    left: 0;
    top: 32%;
    width: 164px;
    height: 321px;
    z-index: -1;
}
.testi-bottom-shape {
    position: absolute;
    right: 0;
    top: 72%;
    width: 263px;
    height: 509px;
}
.testimonial-img-wrap ul li {
    position: absolute;
}
.testimonial-img-wrap ul li img {
    border-radius: 50%;
    border: 1.5px solid #f3f1ff;
    box-shadow: 0px 9px 16.74px 1.26px rgba(0, 0, 0, 0.08);
    padding: 3px;
    background: #fff;
}
.testimonial-img-wrap ul li:nth-child(1) {
    left: 0;
    top: 16%;
    opacity: .84;
}
.testimonial-img-wrap ul li:nth-child(2) {
    bottom: -40px;
    left: 18%;
    opacity: .80;
}
.testimonial-img-wrap ul li:nth-child(3) {
    right: 5%;
    top: -25px;
    opacity: .76;
}
.testimonial-img-wrap ul li:nth-child(4) {
    right: 10%;
    bottom: 0;
    opacity: .63;
}

/* 18. Gallery */
.cons-gallery-area {
    padding-bottom: 90px;
}
.cons-gallery-item {
    margin-bottom: 30px;
}
.cons-gallery-item img {
    max-width: 100%;
    box-shadow: 0px 6px 5.58px 0.42px rgba(28, 27, 42, 0.08);
}

/* 19. Blog */
.blog-bg {
    background-position: center;
    background-size: cover;
    padding: 110px 0 90px;
}
.blog-post-item {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    transition: .3s linear;
}
.blog-post-item:hover {
    box-shadow: 0px 9px 7.44px 0.56px rgba(78, 69, 173, 0.08);
}
.blog-post-thumb {
    overflow: hidden;
}
.blog-post-thumb img {
    transform: scale(1) rotate(0);
    transition: .3s linear;
}
.blog-post-item:hover .blog-post-thumb img {
    transform: scale(1.1) rotate(-1deg);
}
.blog-post-content {
    border: 1px solid #ebebeb;
    border-radius: 0 0 10px 10px;
    border-top: none;
    padding: 30px 30px 35px;
}
.blog-post-meta ul {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 13px;
}
.blog-post-meta ul li {
    font-size: 13px;
    text-transform: capitalize;
    position: relative;
    padding-right: 17px;
    margin-right: 15px;
    color: #838181;
}
.blog-post-meta ul li::before {
    content: "|";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #d7d3d3;
    font-size: 13px;
}
.blog-post-meta ul li:last-child {
    margin-right: 0;
    padding-right: 0;
}
.blog-post-meta ul li:last-child::before {
    display: none;
}
.blog-post-meta ul li i {
    color: #6153fc;
    margin-right: 10px;
    font-size: 12px;
}
.blog-post-meta ul li a {
    color: #838181;
}
.blog-post-content .title {
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 25px;
    letter-spacing: -.3px;
}
.blog-post-content .title a:hover,
.blog-post-meta ul li a:hover {
    color: #6153fc;
}
.blog-post-content .read-more {
    display: inline-flex;
    align-items: center;
    color: #6153fc;
    font-size: 14px;
    text-transform: capitalize;
}
.blog-post-content .read-more i {
    margin-left: 8px;
}

.blog-gray-bg {
    background: #f8f6f5;
    padding: 120px 0 120px;
}
.blog-gray-bg .container-inner-wrap > .row:first-child > [class*="col-"]:first-child {
    padding-right: 35px;
}
.standard-blog-item {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 6.51px 0.49px rgba(95, 95, 100, 0.08);
}
.blog-thumb img {
    max-width: 100%;
}
.standard-blog-content {
    padding: 40px 45px;
}
.standard-blog-content > .title {
    font-size: 26px;
    color: #3b3663;
    margin-bottom: 23px;
}
.standard-blog-content .title a:hover {
    color: #6153fc;
}
.standard-blog-meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.standard-blog-meta li {
    margin-right: 10px;
}
.standard-blog-meta li:last-child {
    margin-right: 0;
}
.standard-blog-meta li a {
    display: block;
    font-size: 12px;
    color: #706d86;
    padding: 5px 14px;
    background: #f1f0fb;
    border-radius: 4px;
    margin-bottom: 10px;
}
.standard-blog-meta li a i {
    margin-right: 8px;
}
.standard-blog-meta li a:hover {
    background: #6153fc;
    color: #fff;
}
.standard-blog-content  p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0;
}
.standard-blog-content .blog-line {
    width: 100%;
    height: 6px;
    background-repeat: repeat;
    margin: 30px 0;
}
.standard-blog-content .read-more {
    box-shadow: none;
    padding: 14px 30px;
}
.standard-blog-item:last-child {
    margin-bottom: 0;
}
.blog-sidebar {
    background: #fff;
    border-radius: 12px;
    padding: 45px 30px 55px;
    box-shadow: 0px 2px 6.51px 0.49px rgba(95, 95, 100, 0.08);
    margin-left: -6px;
}
.blog-sidebar .widget {
    margin-bottom: 40px;
}
.blog-sidebar .widget:last-child {
    margin-bottom: 0;
}
.blog-sidebar .widget-title {
    line-height: 1;
    text-transform: uppercase;
    font-size: 18px;
    color: #3b3663;
    margin-bottom: 23px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.sidebar-search-form form {
    position: relative;
}
.sidebar-search-form input {
    border: none;
    background: #f1f0fb;
    width: 100%;
    display: block;
    padding: 17px 35px 17px 45px;
    border-radius: 5px;
    font-size: 14px;
}
.sidebar-search-form input::placeholder {
    font-size: 14px;
    color: #898799;
}
.sidebar-search-form form::before {
    content: "\f103";
    position: absolute;
    left: 22px;
    top: 50%;
    transform: translateY(-50%);
    font-family: Flaticon;
    font-size: 13px;
    color: #898799;
}
.rc-post-list ul li {
    display: flex;
    align-items: center;
    padding-bottom: 29px;
    margin-bottom: 27px;
    position: relative;
}
.rc-post-list ul li::before,
.rc-post-list ul li::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px dashed #c7c7c7;
}
.rc-post-list ul li::after {
    bottom: 3px;
}
.rc-post-list ul li:last-child {
    margin-bottom: 0;
}
.rc-post-thumb {
    width: 85px;
    flex: 0 0 85px;
    margin-right: 17px;
}
.rc-post-thumb img {
    max-width: 100%;
    border-radius: 5px;
    transition: .3s linear;
}
.rc-post-content {
    flex-grow: 1;
}
.rc-post-content .title {
    font-size: 16px;
    color: #3b3663;
    font-weight: 700;
    margin-bottom: 7px;
    line-height: 1.4;
}
.rc-post-content .title a:hover {
    color: #3b3663;
}
.rc-post-content .date {
    display: block;
    transition: .3s linear;
    font-size: 13px;
    color: #898799;
}
.rc-post-content .date i {
    margin-right: 5px;
}
.rc-post-list ul li:hover .rc-post-thumb img {
    box-shadow: 0px 3px 7.44px 0.56px rgba(169, 168, 168, 0.3);
}
.rc-post-list ul li:hover .rc-post-content .date {
    color: #ff4b36;
}
.sidebar-cat-list ul li {
    padding-bottom: 13px;
    border-bottom: 1px dashed #c7c7c7;
    margin-bottom: 20px;
}
.sidebar-cat-list ul li:last-child {
    margin-bottom: 0;
}
.sidebar-cat-list ul li a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #5d5a77;
    font-weight: 400;
}
.sidebar-cat-list ul li a i {
    margin-left: auto;
    font-size: 12px;
    color: #898799;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.sidebar-cat-list ul li a:hover,
.sidebar-cat-list ul li a:hover i {
    color: #ff4b36;
}
.sidebar-add {
    text-align: center;
}
.sidebar-add img {
    max-width: 100%;
}
.sidebar-tag-list ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3.5px;
    margin-right: -3.5px;
    margin-top: -10px;
}
.sidebar-tag-list ul li {
    padding-left: 3.5px;
    padding-right: 3.5px;
    padding-top: 10px;
}
.sidebar-tag-list ul li a {
    display: block;
    background: #f1f0fb;
    font-size: 14px;
    font-weight: 400;
    color: #5d5a77;
    padding: 9px 20px;
    border-radius: 50px;
}
.sidebar-tag-list ul li a:hover {
    background: #ff4b36;
    color: #fff;
}
.blog-details-content p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 20px;
}
.blog-details-img .row {
    margin-left: -10px;
    margin-right: -10px;
}
.blog-details-img .row [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
}
.blog-details-content blockquote {
    margin: 31px 0 28px;
    font-size: 20px;
    font-weight: 600;
    color: #3b3663;
    font-style: italic;
    line-height: 1.6;
    padding: 3px 28px;
    position: relative;
}
.blog-details-content blockquote::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: #6153fc;
    border-radius: 2px;
}
.blog-details-content blockquote footer {
    font-size: 14px;
    font-style: normal;
    color: #6153fc;
    font-weight: 500;
    position: relative;
    padding-left: 30px;
    margin-top: 11px;
}
.blog-details-content blockquote footer::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 2px;
    background: #6153fc;
}
.blog-details-list ul li {
    font-size: 14px;
    line-height: 26px;
    position: relative;
    padding-left: 20px;
}
.blog-details-list ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
    width: 7px;
    height: 7px;
    background: #6153fc;
    border-radius: 50%;
}
.blog-details-list ul li a {
    color: #6153fc;
    text-decoration: underline;
}
.blog-details-list ul li + li {
    margin-top: 10px;
}
.blog-details-img {
    margin: 35px 0 32px;
}
.blog-details-img img {
    max-width: 100%;
    border-radius: 6px;
}
.blog-details-bottom {
    display: flex;
    align-items: center;
}
.blog-details-tags {
    width: 70%;
    flex: 0 0 70%;
}
.blog-details-tags ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.blog-details-tags ul li.title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: #3b3663;
    margin-right: 12px;
    padding-right: 0;
}
.blog-details-tags ul li.title::before {
    display: none;
}
.blog-details-tags ul li.title i {
    font-size: 10px;
    color: #6153fc;
    margin-right: 5px;
}
.blog-details-tags ul li {
    padding-right: 13px;
    margin-right: 13px;
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
}
.blog-details-tags ul li::before {
    content: "|";
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    color: #898797;
    font-weight: 500;
}
.blog-details-tags ul li:last-child {
    margin-right: 0;
    padding-right: 0;
}
.blog-details-tags ul li:last-child:before {
    display: none;
}
.blog-details-tags ul li a {
    display: block;
    font-size: 13px;
    color: #5d5a77;
}
.blog-details-tags ul li a:hover {
    color: #ff4b36;
}
.blog-details-social {
    flex-grow: 1;
}
.blog-details-social ul {
    justify-content: flex-end;
}
.blog-details-content .blog-line {
    margin: 45px 0 20px;
}
.avatar-post {
    background: #fff;
    padding: 40px 60px 40px 40px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 0px 9px 0px rgba(160, 157, 157, 0.12);
}
.post-avatar-img {
    margin-right: 30px;
    width: 128px;
    flex: 0 0 128px;
}
.post-avatar-img img {
    max-width: 100%;
    border-radius: 8px;
}
.post-avatar-content h5 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
.post-avatar-content .blog-details-social ul {
   justify-content: flex-start;
}
.post-avatar-content p {
    font-size: 14px;
    color: #5d5a77;
    margin-bottom: 15px;
    line-height: 26px;
}
.blog-next-prev ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.blog-next-prev ul li a {
    display: block;
    font-size: 15px;
    color: #3b3663;
    text-transform: uppercase;
    font-weight: 700;
}
.blog-prev img {
	margin-right: 15px;
}
.blog-next img {
	margin-left: 15px;
}
.blog-next-prev ul li a:hover {
    color: #6153fc;
}
.comment-reply-box {
    background: #fff;
    padding: 60px 45px 65px;
    margin-top: 50px;
    border-radius: 8px;
    box-shadow: 0px 0px 9px 0px rgba(160, 157, 157, 0.12);
}
.comment-reply-box .title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    color: #3b3663;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 40px;
    padding-bottom: 12px;
    position: relative;
}
.comment-reply-box .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 1px;
    background: #ff4b36;
}
.comment-reply-form .row {
    margin-left: -10px;
    margin-right: -10px;
}
.comment-reply-form .row [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
}
.comment-reply-form .form-grp {
    margin-bottom: 20px;
}
.comment-reply-form input,
.comment-reply-form textarea {
    border: none;
    background: #f1f0fb;
    width: 100%;
    padding: 13px 25px;
    font-size: 14px;
    color: #5d5a77;
    border-radius: 4px;
    font-weight: 500;
}
.comment-reply-form input::placeholder,
.comment-reply-form textarea::placeholder {
    font-size: 14px;
    color: #5d5a77;
    font-weight: 500;
    opacity: .77;
}
.comment-reply-form textarea {
    min-height: 148px;
    max-height: 148px;
}
.comment-reply-form .btn {
    box-shadow: none;
    padding: 14px 31px;
}
.comment-reply-form .btn:hover {
    background: #ff4b36;
}

/* 20. Contact */
#map-bg {
    min-height: 490px;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    position: relative;
}
#map-bg .map-marker {
    position: absolute;
    left: 43%;
    top: 36%;
    width: 93px;
    height: 93px;
    text-align: center;
    line-height: 93px;
    background: #ff4b36;
    font-size: 40px;
    color: #fff;
    border-radius: 50%;
}
.ripple-white {
    border-radius: 50%;
    -webkit-animation: ripple-white 1s linear infinite;
    animation: ripple-white 1s linear infinite;
    transition: .5s;
    -webkit-transition: .5s;
}
@-webkit-keyframes ripple-white {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255,0.5), 0 0 0 10px rgba(255,255,255,0.5), 0 0 0 20px rgba(255,255,255,0.5);
        box-shadow: 0 0 0 0 rgba(255,255,255,0.5), 0 0 0 10px rgba(255,255,255,0.5), 0 0 0 20px rgba(255,255,255,0.5);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255,0.5), 0 0 0 20px rgba(255,255,255,0.5), 0 0 0 30px rgba(255,255,255,0);
        box-shadow: 0 0 0 10px rgba(255,255,255,0.5), 0 0 0 20px rgba(255,255,255,0.5), 0 0 0 30px rgba(255,255,255,0);
    }
}
@keyframes ripple-white {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255,0.5), 0 0 0 10px rgba(255,255,255,0.5), 0 0 0 20px rgba(255,255,255,0.5);
        box-shadow: 0 0 0 0 rgba(255,255,255,0.5), 0 0 0 10px rgba(255,255,255,0.5), 0 0 0 20px rgba(255,255,255,0.5);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255,0.5), 0 0 0 20px rgba(255,255,255,0.5), 0 0 0 30px rgba(255,255,255,0);
        box-shadow: 0 0 0 10px rgba(255,255,255,0.5), 0 0 0 20px rgba(255,255,255,0.5), 0 0 0 30px rgba(255,255,255,0);
    }
}
.contact-wrap-content p {
    font-size: 14px;
    padding-right: 20px;
    margin-bottom: 30px;
}
.contact-form .form-grp {
    margin-bottom: 25px;
}
.contact-form .form-grp label {
    font-size: 14px;
    font-weight: 500;
    color: #5d5a77;
    margin-bottom: 10px;
    opacity: .77;
}
.contact-form .form-grp label span {
    color: #6140ff;
}
.contact-form .form-grp input,
.contact-form .form-grp textarea {
    width: 100%;
    background: #f1f0fb;
    border: none;
    font-size: 14px;
    padding: 15px 20px;
    border-radius: 5px;
    display: block;
    font-weight: 500;
}
.contact-form .form-grp input::placeholder,
.contact-form .form-grp textarea::placeholder {
    color: #5d5a77;
    font-size: 14px;
    font-weight: 500;
    opacity: .6;
}
.contact-form .form-grp textarea {
    min-height: 125px;
    max-height: 125px;
}
.contact-form .checkbox-grp,
.comment-reply-form .checkbox-grp {
    display: flex;
    align-items: flex-start;
    margin-bottom: 35px;
}
.contact-form .checkbox-grp input,
.comment-reply-form .checkbox-grp input {
    width: auto;
    margin-top: 3px;
    margin-right: 9px;
}
.contact-form .checkbox-grp label,
.comment-reply-form .checkbox-grp label {
    font-size: 14px;
    font-weight: 500;
    color: #5d5a77;
    opacity: .8;
    margin-bottom: 0;
}
.contact-form [type=button] {
    box-shadow: none;
    padding: 14px 32px;
}
.contact-form [type=button]:hover,
.standard-blog-content .read-more:hover {
    background: #ff4b36;
}
.contact-info-wrap {
    background: #f1f0fb;
    border-radius: 12px;
    padding: 45px 55px;
}
.contact-img {
    text-align: center;
    margin-bottom: 55px;
}
.contact-img img {
    max-width: 100%;
}
.contact-info-list ul li {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
}
.contact-info-list ul li:last-child {
    margin-bottom: 0;
}
.contact-info-list .icon {
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    background: #fff;
    flex: 0 0 45px;
    border-radius: 50%;
    margin-right: 15px;
    font-size: 16px;
    color: #6153fc;
    box-shadow: 0px 2px 14.88px 1.12px rgba(97, 83, 252, 0.11);
}
.contact-info-list .content {
    flex-grow: 1;
}
.contact-info-list .content p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 2;
    color: #898799;
}
.contact-social ul,
.blog-details-social ul {
    display: flex;
    align-items: center;
}
.contact-social ul li {
    margin-right: 15px;
}
.blog-details-social ul li {
    margin-right: 10px;
}
.contact-social ul li:last-child,
.blog-details-social ul li:last-child {
    margin-right: 0;
}
.contact-social ul li a,
.blog-details-social ul li a {
    display: block;
}
.contact-social ul li a i,
.blog-details-social ul li a i {
    width: 41px;
    height: 41px;
    display: block;
    text-align: center;
    line-height: 41px;
    border-radius: 50%;
    background: #385594;
    color: #fff;
    font-size: 14px;
}
.blog-details-social ul li a i {
    width: 31px;
    height: 31px;
    line-height: 31px;
    font-size: 12px;
}
.contact-social ul li:nth-child(2) a i,
.blog-details-social ul li:nth-child(2) a i {
    background: #4eabee;
}
.contact-social ul li:nth-child(3) a i,
.blog-details-social ul li:nth-child(3) a i {
    background: #1976d2;
}
.contact-social {
    margin-top: 45px;
}

/* 21. Footer */
.footer-top-wrap {
    padding: 95px 0 45px;
}
.footer-top-wrap .footer-widget {
    margin-bottom: 50px;
}
.fw-title .title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: #3b3663;
    margin-bottom: 0;
}
.footer-widget .fw-title {
    margin-bottom: 30px;
}
.fw-link ul li {
    margin-bottom: 13px;
}
.fw-link ul li:last-child {
    margin-bottom: 0;
}
.fw-link ul li a {
    display: inline-block;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    color: #898799;
}
.fw-link ul li a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #6153fc;
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
    transition: transform 0.4s cubic-bezier(.74,.72,.27,.24);
}
.fw-link ul li a:hover::before {
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}
.fw-link ul li a:hover {
    color: #6153fc;
}
.fw-social ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.fw-social ul li + li {
    margin-left: 13px;
}
.fw-social ul li a {
    display: block;
}
.fw-social ul li a i {
    display: block;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    font-size: 12px;
    border-radius: 6px;
    color: #fff;
    background: #6153fc;
}
.fw-social ul li a .fa-twitter {
    background: #1da1f2;
}
.fw-social ul li a .fa-instagram {
    background: #9254de;
}
.fw-social ul li a .fa-linkedin-in {
    background: #1976d2;
}
.fw-social ul li a .fa-youtube {
    background: #f44336;
}
.copyright-wrap {
    background: #6153fc;
    padding: 20px 0;
}
.footer-lang img {
    max-width: 18px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-right: 6px;
}
.copyright-text {
    display: flex;
    align-items: flex-end;
}
.copyright-text p {
    font-size: 13px;
    color: #f8f8f8;
    line-height: 24px;
    margin-bottom: 3px;
    position: relative;
    padding-left: 15px;
    margin-left: 25px;
}
.copyright-text p::before {
    content: "|";
    position: absolute;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    color: #8479fd;
}
.footer-lang .dropdown {
    text-align: right;
}
.footer-lang .dropdown > button {
    border: none;
    background: transparent;
    padding: 0;
    color: #f8f8f8;
    font-size: 13px;
}
.footer-lang .dropdown .dropdown-item {
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 13px;
    padding: 4px 15px;
}
.footer-bg {
    background-image: url(../img/bg/footer_bg.jpg);
    background-position: center;
    background-size: cover;
    padding: 100px 0 50px;
}
.footer-content p {
    font-size: 14px;
    color: #b8b8b8;
    line-height: 24px;
    margin-bottom: 0;
}
.footer-info-list {
    margin-top: 25px;
}
.footer-info-list li {
    font-size: 14px;
    color: #b8b8b8;
    display: flex;
    align-items: center;
}
.footer-info-list li + li {
    margin-top: 15px;
}
.footer-info-list li i {
    margin-right: 10px;
    color: #ff4b36;
}
.footer-bg .footer-widget .fw-title {
    margin-bottom: 30px;
    display: flex;
    align-items: flex-end;
    color: #fff;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    margin-right: 30px;
}
.footer-bg .footer-widget .fw-title .line {
    display: block;
    height: 1px;
    background: #6e6889;
    flex-grow: 1;
    margin: 0 7px 5px 15px;
}
.footer-bg .footer-widget .fw-title .dots {
    line-height: 1;
    color: #ff4b36;
}
.footer-bg .fw-link ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.footer-bg .fw-link ul li {
    margin-bottom: 15px;
    width: 44%;
    flex: 0 0 44%;
}
.footer-bg .fw-link ul li a i {
    margin-right: 10px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.footer-bg .fw-link ul li a {
    color: #b8b8b8;
    display: inline-flex;
    align-items: center;
}
.footer-bg .fw-link ul li a:hover i {
    color: #ff4b36;
}
.footer-bg .fw-link ul li a::before {
    display: none;
}
.footer-newsletter-wrap p {
    font-size: 14px;
    color: #b8b8b8;
    line-height: 26px;
    margin-bottom: 17px;
}
.footer-newsletter-wrap p span {
    color: #ff4b36;
}
.footer-newsletter-wrap form {
    position: relative;
}
.footer-newsletter-wrap form input {
    width: 100%;
    border: none;
    padding: 20px 80px 20px 25px;
    border-radius: 4px;
    color: #7d7d7d;
    font-size: 14px;
}
.footer-newsletter-wrap form button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: none;
    border-radius: 4px;
    width: 65px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #ff4b36;
}
.footer-confi-wrap {
    display: flex;
    align-items: center;
    margin-top: 35px;
}
.confi-logo {
    width: 81px;
    flex: 0 0 81px;
    margin-right: 15px;
}
.confi-logo img {
    max-width: 100%;
}
.confi-content h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 9px;
}
.confi-content span {
    display: block;
    font-size: 14px;
    color: #b8b8b8;
}
.s-copyright-wrap {
    background: #2d2952;
    padding: 29px 0;
}
.s-copyright-text p {
    margin-bottom: 0;
    font-size: 14px;
    color: #b8b8b8;
}
.s-copyright-text p a {
    color: #ff4b36;
}
.payment-method-img img {
    max-width: 100%;
}

/* 22. Preloader */
#preloader{
	background-color: #fff;
	height: 100%;
	width: 100%;
	position: fixed;
	margin-top: 0px;
	top: 0px;
	z-index: 999;
}
#loading-center {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
}
.loader .loader-outter {
    position: absolute;
    border: 4px solid #ff4b36 ;
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: loader-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
}
.loader .loader-inner {
    position: absolute;
    border: 4px solid #ff4b36 ;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    border-right: 0;
    border-top-color: transparent;
    -webkit-animation: loader-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
}
@-webkit-keyframes loader-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

 @-webkit-keyframes loader-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

 @keyframes loader-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}
